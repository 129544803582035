@use 'valiables' as *;

/* ================================================================================
mixin定義
================================================================================ */

// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// clearfix
// 使用例：@include clearfix;
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

// PCサイズ Retina画像出し分け用
// 使用例：@include media-retina {background-image: url('~~~@2x.jpg');}
@mixin media-retina() {
  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5) {
    @content;
  }
}

// VW設定PC
@function get_vw_pc($size, $viewport:1366) {
  @return (100 / $viewport) * $size * 1vw;
}
// VW設定タブレット
@function get_vw_tab($size, $viewport:768) {
  @return (100 / $viewport) * $size * 1vw;
}
// VW設定SP
@function get_vw_sp($size, $viewport:750) {
  @return (100 / $viewport) * $size * 1vw;
}

// フォントサイズ
@mixin fz_vw($font_size:10) {
  font-size: $font_size * 0.1rem;
  font-size: get_vw_pc($font_size);
  @include mq(fixed) {
    font-size: $font_size * 0.1rem;
  }
  @include mq(tab) {
    font-size: get_vw_tab($font_size);
  }
  @include mq(md) {
    font-size: get_vw_sp($font_size);
  }
}

// 余白計算
@mixin props($property, $parent_width, $size) {
  #{map-get($properties, $property)}: ($size / $parent_width) * 100%;
}