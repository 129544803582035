@use '../global/' as *;

/* =====================================================
アニメーション関連
===================================================== */
/* スクロールでフェードイン */
.u-fade {
  transition: opacity 5s cubic-bezier(0.19, 1, 0.22, 1),
  visibility 5s cubic-bezier(0.19, 1, 0.22, 1),
  transform 5s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  visibility: hidden;

  &__top {
    transform: translate(0, -50px);
  }
  &__bottom {
    transform: translate(0, 50px);
  }
  &__right {
    transform: translate(50px, 0);
  }
  &__left {
    transform: translate(-50px, 0);
  }
  &__zoom {
    transform: scale(.6);
  }
  &__slide {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s, visibility 1s, clip-path 1s;
    clip-path: inset(0 100% 0 0);

    &--reverse {
      clip-path: inset(0 0 0 100%);
    }

    &.u-fade--in {
      clip-path: inset(0 0 0 0);
    }
  }

  &--late {
    transition-delay: .25s;
  }

  &--in {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px) scale(1);
  }
}

/* ローディング */
.load {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $basicColor;
  img {
    opacity: 0;
    width: 220px;
    transition: all 1s;
  }

  &--loading {
    img {
      opacity: 1;
    }
  }

  &--loaded {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s, visibility 1s;
  }
}

/* トップページのロゴ */
.logo-animation {
  span {
    display: inline-block;
    opacity: 0;
  }

  &.logo-animation--started {
    span {
      transform: translate(0, 0) rotate(0) scale(.5);
      animation: revolveScale .8s forwards;

      &:nth-of-type(2) {
        animation-delay: .20s;
      }
      &:nth-of-type(3) {
        animation-delay: .40s;
      }
      &:nth-of-type(4) {
        animation-delay: .60s;
      }
      &:nth-of-type(5) {
        animation-delay: .80s;
      }
      &:nth-of-type(6) {
        animation-delay: 1.00s;
      }
      &:nth-of-type(7) {
        animation-delay: 1.20s;
      }
      &:nth-of-type(8) {
        animation-delay: 1.40s;
      }
      &:nth-of-type(9) {
        animation-delay: 1.60s;
      }
      &:nth-of-type(10) {
        animation-delay: 1.80s;
      }
      &:nth-of-type(11) {
        animation-delay: 2.00s;
      }
      &:nth-of-type(12) {
        animation-delay: 2.20s;
      }
      &:nth-of-type(13) {
        animation-delay: 2.40s;
      }
      &:nth-of-type(14) {
        animation-delay: 2.60s;
      }
    }

    @keyframes revolveScale {
      0% {
        opacity: 1;
      }
      60% {
        opacity: .5;
        transform: translate(0, 0) rotate(0) scale(.3);
      }

      100% {
        transform: translate(0) rotate(0) scale(1);
        opacity: 1;
      }
    }
  }
}
