@use '../../global/' as *;

/* ================================================================================
基本設定
================================================================================ */

* {
  box-sizing: border-box;
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: $basicColor;
}

a {
  color: $basicColor;
}