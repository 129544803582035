@use '../../global/' as *;

/* ================================================================================
スライド
================================================================================ */
.swiper {
  &-wrapper {
    transition-timing-function: linear !important;
  }

  &-slide {
    height: 168px !important;
    width: auto !important;
    padding-right: 1px;
    @include mq(md) {
      height: 129px !important;
    }

    img {
      width: auto;
      height: 100%;
    }
  }
}