@use '../../global/' as *;

/* ================================================================================
タイトル
================================================================================ */
.block{
  &-titile {
    background-image: url(../images/bg_title.svg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 108px 37px;
    margin-bottom: 40px;
    padding-top: 40px;
    text-align: center;
    font-size: 3.2rem;
    font-weight: 700;
    color: $greenColor;
    @include mq(md) {
      background-size: 68px 23px;
      margin-bottom: 30px;
      padding-top: 30px;
      font-size: 2.6rem;
    }
  }

  &-subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 384px;
    width: 100%;
    height: 60px;
    margin: 0 auto 40px;
    background-color: $blueColor;
    text-align: center;
    font-size: 2.6rem;
    font-weight: 700;
    color: $basicWhiteColor;
    @include mq(md) {
      height: 50px;
      margin: 0 auto 30px;
      font-size: 2.2rem;
    }
  }
}