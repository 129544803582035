@use '../global/' as *;

/* ================================================================================
トップページ
================================================================================ */
.mainvisual {
  position: relative;
  height: calc(100vh);
  overflow: hidden;

  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 3s opacity;
    opacity: 0;

    &--show {
      opacity: 1;
    }
  }

  &__logo {
    position: absolute;
    top: 22%;
    left: 50%;
    transform: translateX(-50%);
    height: 40vh;
    transition: 1s opacity;
    opacity: 0;
    @include mq(md) {
      height: 25vh;
      top: 28%;
    }

    &--show {
      opacity: 1;
    }

    img {
      max-width: none;
      height: 100%;
    }
  }

  &__text {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: $basicWhiteColor;
    transition: 1s opacity;
    opacity: 0;
    @include mq(md) {
      top: 55%;
      // position: relative;
    }

    &--show {
      opacity: 1;
    }

    &-top {
      display: inline-block;
      margin-bottom: 10px;
      border-bottom: 2px $basicWhiteColor dashed;
      font-size: 3vh;
      // @include mq(md) {
      //   font-size: 2.6rem;
      // }
    }
    &-bottom {
      font-size: 2vh;
      // @include mq(md) {
      //   font-size: 1.4rem;
      // }
    }
  }

  &__scroll {
    position:absolute;
    z-index: 999;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    transition: 1s opacity;
    opacity: 0;

    &--show {
      opacity: 1;
    }

    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 30px;
      background: $basicWhiteColor;
      animation: pathmove 1.4s ease-in-out infinite;
      opacity:0;

      @keyframes pathmove{
        0%{
          height:0;
          top:0;
          opacity: 0;
        }
        30%{
          height:30px;
          opacity: 1;
        }
        100%{
          height:0;
          top:50px;
          opacity: 0;
        }
      }
    }

    span{
      position: absolute;
      color: $basicWhiteColor;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
    }
  }
}
