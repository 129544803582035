@use '../global/' as *;

/* ================================================================================
汎用クラス
================================================================================ */

// コンテナ
.u-container {
  $padding: 20px;
  max-width: calc(#{$basicWidth} + (#{$padding} * 2));
  padding: 0 $padding;
  margin: 0 auto;
  @include mq(md) {
    padding: 0 15px;
  }
}

.u-inner-container {
  $padding: 20px;
  max-width: calc(#{$innerWidth} + (#{$padding} * 2));
  padding: 0 $padding;
  margin: 0 auto;
  @include mq(md) {
    padding: 0 15px;
  }
}

// PC/SPで要素を出しわけ
.u-pc-only {
  @include mq(md) {
    display: none !important;
  }
}
.u-sp-only {
  @include mq(pc) {
    display: none !important;
  }
}

.u-highlight {
  background: linear-gradient(transparent 0%, #FFE9B7 0%);
}

.u-text-center {
  text-align: center;
}
.u-text-right {
  text-align: right;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-mb-50 {
  margin-bottom: 27px !important;
  @include mq(pc) {
    margin-bottom: 50px !important;
  }
}