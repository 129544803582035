@use '../global/' as *;

/* ================================================================================
トップページ
================================================================================ */
.shugen {
  padding: 60px 0 80px;
  p {
    line-height: 1.8;
    margin-bottom: 1.8em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.about {
  background-color: $greenColor;
  padding: 80px 0;

  @media screen and (max-width: ($basicWidth + 40px)) {
    padding-left: 20px;
    padding-right: 20px;

    @include mq(md) {
      padding: 15px;
    }
  }

  &-inner {
    width: 100%;
    max-width: $basicWidth;
    margin: 0 auto;
    padding: 60px 0;
    background-color: $basicWhiteColor;
    border-radius: 5px;
  }

  p {
    line-height: 1.8;
    margin-bottom: 1.8em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-intro {
    margin-bottom: 60px;
  }

  &-mind {
    margin-bottom: 60px;

    &-item {
      display: flex;
      gap: 40px;
      margin-bottom: 60px;
      .text {
        max-width: 610px;
        flex: 1;
        margin-left: 60px;
      }
      .photo {
        width: 490px;
        margin-right: -200px;
      }

      &--reverse {
        flex-direction: row-reverse;
        .text {
          margin-left: 0;
          margin-right: 60px;
        }
        .photo {
          margin-right: 0;
          margin-left: -200px;
        }
      }

      @media screen and (max-width: 1400px) {
        .text {
          margin-left: 40px;
        }
        .photo {
          margin-right: calc(((100vw - #{$basicWidth}) / 2 * -1));
        }

        &--reverse {
          flex-direction: row-reverse;
          .text {
            margin-left: 0;
            margin-right: 40px;
          }
          .photo {
            margin-right: 0;
            margin-left: calc(((100vw - #{$basicWidth}) / 2 * -1));
          }
        }
      }

      @include mq(tabmd) {
        $padding: 20px;
        display: block;
        max-width: calc(#{$innerWidth} + (#{$padding} * 2));
        padding: 30px 15px;
        margin: 0 auto;
        border-bottom: 2px dashed $borderColor;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }

        .text {
          max-width: none;
          margin: 0 0 30px;
        }
        .photo {
          width: 100%;
          max-width: 350px;
          margin: 0 auto;
        }
      }
    }
  }

  &-guide {
    $padding: 20px;
    margin: 0 auto;

    &-item {
      max-width: calc(710px + ($padding * 2));
      margin: 0 auto;
      padding: 20px $padding;
      border-bottom: 2px dashed $borderColor;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
      @include mq(md) {
        margin: 0 15px;
        padding: 30px 0;
      }

      &-top {
        display: flex;
        align-items: center;
        gap: 40px;
        margin-bottom: 20px;
        @include mq(md) {
          display: block;
          margin-bottom: 10px;
        }

        .photo {
          max-width: 264px;
          @include mq(md) {
            margin: 0 auto 10px;
          }
        }
        .name {
          margin-bottom: 10px;
          color: $greenColor;
          font-size: 2.2rem;
          font-weight: 700;
          @include mq(md) {
            font-size: 2.0rem;
            text-align: center;
          }
        }
        .profile {
          margin-left: 15px;
          font-size: 1.3rem;
          font-weight: 700;
          color: #8D8D8D;

          li {
            position: relative;
            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              left: -15px;
              top: 50%;
              transform: translateY(-50%);
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: $greenColor;
            }
          }
        }
      }
    }
  }
}

.movie {
  position: relative;
  background-image: url(http://img.youtube.com/vi/Fl8Fw4Xd1K4/maxresdefault.jpg);
  background-size: cover;
  background-position: center center;
  height: 500px;

  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $basicColor, $alpha: 0.5);
  }

  &-inner {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__text {
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: $basicWhiteColor;

    &-top {
      display: inline-block;
      margin-bottom: 10px;
      border-bottom: 2px $basicWhiteColor dashed;
      font-size: 2.8rem;
      @include mq(md) {
        font-size: 2.6rem;
      }
    }
    &-bottom {
      font-size: 1.8rem;
      @include mq(md) {
        font-size: 1.6rem;
      }
    }
  }

  &__play {
    text-align: center;
    a {
      display: inline-block;
      margin-top: 30px;
      transition: .3s transform;
      img {
        width: 78px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.tour {
  padding: 60px 0 80px;
  background-color: $bgColor;
  @include mq(md) {
    padding: 60px 0 60px;
  }

  p {
    line-height: 1.8;
    margin-bottom: 1.8em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-intro {
    background-image: url(../images/bg_tour_intro_pc.svg);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 60px;
    @media screen and (max-width: $basicWidth) {
      position: relative;
      background: none;
      border-top: 2px dashed $borderColor;
      border-bottom: 2px dashed $borderColor;
      background-color: $basicWhiteColor;
      margin: 60px 0 95px;

      &::after, &::before {
        content: "";
        position: absolute;
        width: 100%;
        background-position: center center;
        background-size: contain;
        left: 50%;
        background-repeat: no-repeat;
      }
      &::after {
        top: 0;
        transform: translate(-50%, -50%);
        height: 77px;
        background-image: url(../images/bg_tour_intro_sp01.svg);
      }
      &::before {
        bottom: 0;
        transform: translate(-50%, 50%);
        height: 87px;
        background-image: url(../images/bg_tour_intro_sp02.svg);
      }
    }

    &__text {
      max-width: 540px;
      margin: 0 auto;
      padding: 158px 0;
      @media screen and (max-width: $basicWidth) {
        padding: 70px 30px;
      }
    }
  }

  &-features {
    .u-container {
      @include mq(md) {
        padding: 0;
      }
    }

    &-item {
      position: relative;
      height: 550px;
      margin-bottom: 60px;
      @include mq(md) {
        height: auto;
        margin-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .photo {
        position: absolute;
        top: 0;
        left: 0;
        @include mq(md) {
          position: static;
          text-align: center;
        }
        img {
          max-height: 550px;
        }
      }
      .text {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: $basicWhiteColor;
        border-radius: 5px;
        margin: 0 45px;
        padding: 40px;
        @include mq(md) {
          position: relative;
          transform: none;
          margin: -30px 15px 0;
          padding: 50px 40px 60px;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          width: 90px;

          @include mq(md) {
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
          }
        }
      }

      &--reverse {
        .photo {
          left: auto;
          right: 0;
        }
        .text {
          right: auto;
          left: 0;

          img {
            right: auto;
            left: 0;
            transform: translate(-50%, -50%);
          }
        }
      }

      &:nth-child(1) {
        .text {
          max-width: 680px;
          background-image: url(../images/bg_tour_features01.svg);
          background-repeat: no-repeat;
          background-size: 76px 61px;
          background-position: bottom 5px right 5px;
          @include mq(md) {
            max-width: none;
            background-position: bottom 10px right 15px;
          }
        }
      }
      &:nth-child(2) {
        .text {
          max-width: 680px;
          background-image: url(../images/bg_tour_features02.svg);
          background-repeat: no-repeat;
          background-size: 165px 164px;
          background-position: bottom 15px left 28px;
          @include mq(md) {
            max-width: none;
            background-position: bottom 14px left 9px;
          }
        }
      }
      &:nth-child(3) {
        .text {
          max-width: 540px;
          background-image: url(../images/bg_tour_features03.svg);
          background-repeat: no-repeat;
          background-size: 46px 145px;
          background-position: bottom 30px right 18px;
          @include mq(md) {
            max-width: none;
            background-position: bottom 10px right 10px;
          }
        }
      }
    }
  }
}

.gallery {
  margin: 60px 0 115px;
  @include mq(md) {
    margin: 60px 0;
  }

  &-top {
    margin-bottom: 40px;
    @include mq(md) {
      margin-bottom: 20px;
    }
  }
}