/* ================================================================================
変数定義
================================================================================ */

// Noto Sansを使用する場合はコメントアウト解除
// @forward '../fonts/noto-sans';
// Noto Serifを使用する場合はコメントアウト解除
// @forward '../fonts/serif-sans';
$base-font: 'Zen Kaku Gothic New', sans-serif;

// 追加フォント
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;700&display=swap');

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本横幅
$basicWidth: 1000px;
// 外側横幅
$outerWidth: 1600px;
// 内側横幅
$innerWidth: 640px;

// 基本カラー
$basicColor: #1A0E05;
$basicWhiteColor: #fff;
$orangeColor: #DF6F36;
$greenColor: #1D8A48;
$blueColor: #0B72A7;
$borderColor: #83AD94;
$thinBorderColor: #ccc;
$bgColor: #FFFAF0;

// ブレークポイント定義
$breakpoints: ('md': 'screen and (max-width : 767.99px)',
  'tab': 'screen and (min-width : 768px) and (max-width : 1024px)',
  'pc': 'screen and (min-width : 768px)',
  'tabmd': 'screen and (max-width : 1024px)',
) !default;

// プロパティ短縮
$properties: ('pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'wd': 'width',
  'he': 'height',
  'top': 'top',
  'left': 'left',
  'bottom': 'bottom',
  'right': 'right',
) !default;