@use '../../global/' as *;

/* ================================================================================
フッター
================================================================================ */
/* -------------------------------------------------
.l-footer
---------------------------------------------------- */
.l-footer {
  position: relative;
  padding: 95px 0 20px;
  background: $greenColor;
  color: $basicWhiteColor;
  text-align: center;

  @include mq(md) {
    padding-bottom: 70px;
  }

  &__application {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mq(md) {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 500px;
      height: 70px;
      background-color: $orangeColor;
      background-image: url(../images/ico_contact.svg);
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center right 40px;
      border-radius: 35px;
      border: 1px solid $basicWhiteColor;
      color: $basicWhiteColor;
      font-weight: 700;
      font-size: 2rem;
      transition: .3s transform;

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  &__logo {
    width: 226px;
    margin: 0 auto 40px;
  }

  &__contact {
    margin: 0 auto 35px;

    a {
      color: $basicWhiteColor;
      font-size: 1.4rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  /*
  コピーライト
  ---------------------------------------------------- */
  &__copy {
    font-size: 1.4rem;
    font-weight: 700;
  }
}