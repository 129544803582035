@charset "UTF-8";
/* ================================================================================
soune-wp-template
================================================================================ */
/* ================================================================================
共通パーツ
================================================================================ */
/* ================================================================================
全体で使用する変数・関数定義
================================================================================ */
/* ================================================================================
変数定義
================================================================================ */
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@400;700&display=swap");
/* ================================================================================
mixin定義
================================================================================ */
/* ================================================================================
リセット
================================================================================ */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #1A0E05;
  font-size: 62.5%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #83AD94;
  padding: 0.35em 0.625em 0.75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:link {
  color: #000;
  outline: none;
}
a:visited {
  color: #000;
  outline: none;
}
a:hover {
  text-decoration: none;
  outline: none;
}
a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #83AD94;
}

img {
  max-width: 100%;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type=button],
input[type=submit],
input[type=search],
input[type=reset],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}
input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type=checkbox],
[type=radio] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden=false] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden=false]:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* ================================================================================
汎用クラス
================================================================================ */
.u-container {
  max-width: calc(1000px + 20px * 2);
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (max-width : 767.99px) {
  .u-container {
    padding: 0 15px;
  }
}

.u-inner-container {
  max-width: calc(640px + 20px * 2);
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (max-width : 767.99px) {
  .u-inner-container {
    padding: 0 15px;
  }
}

@media screen and (max-width : 767.99px) {
  .u-pc-only {
    display: none !important;
  }
}

@media screen and (min-width : 768px) {
  .u-sp-only {
    display: none !important;
  }
}

.u-highlight {
  background: linear-gradient(transparent 0%, #FFE9B7 0%);
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-mb-50 {
  margin-bottom: 27px !important;
}
@media screen and (min-width : 768px) {
  .u-mb-50 {
    margin-bottom: 50px !important;
  }
}

/* =====================================================
アニメーション関連
===================================================== */
/* スクロールでフェードイン */
.u-fade {
  transition: opacity 5s cubic-bezier(0.19, 1, 0.22, 1), visibility 5s cubic-bezier(0.19, 1, 0.22, 1), transform 5s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  visibility: hidden;
}
.u-fade__top {
  transform: translate(0, -50px);
}
.u-fade__bottom {
  transform: translate(0, 50px);
}
.u-fade__right {
  transform: translate(50px, 0);
}
.u-fade__left {
  transform: translate(-50px, 0);
}
.u-fade__zoom {
  transform: scale(0.6);
}
.u-fade__slide {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s, visibility 1s, clip-path 1s;
  clip-path: inset(0 100% 0 0);
}
.u-fade__slide--reverse {
  clip-path: inset(0 0 0 100%);
}
.u-fade__slide.u-fade--in {
  clip-path: inset(0 0 0 0);
}
.u-fade--late {
  transition-delay: 0.25s;
}
.u-fade--in {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px) scale(1);
}

/* ローディング */
.load {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #1A0E05;
}
.load img {
  opacity: 0;
  width: 220px;
  transition: all 1s;
}
.load--loading img {
  opacity: 1;
}
.load--loaded {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, visibility 1s;
}

/* トップページのロゴ */
.logo-animation span {
  display: inline-block;
  opacity: 0;
}
.logo-animation.logo-animation--started span {
  transform: translate(0, 0) rotate(0) scale(0.5);
  animation: revolveScale 0.8s forwards;
}
.logo-animation.logo-animation--started span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.logo-animation.logo-animation--started span:nth-of-type(3) {
  animation-delay: 0.4s;
}
.logo-animation.logo-animation--started span:nth-of-type(4) {
  animation-delay: 0.6s;
}
.logo-animation.logo-animation--started span:nth-of-type(5) {
  animation-delay: 0.8s;
}
.logo-animation.logo-animation--started span:nth-of-type(6) {
  animation-delay: 1s;
}
.logo-animation.logo-animation--started span:nth-of-type(7) {
  animation-delay: 1.2s;
}
.logo-animation.logo-animation--started span:nth-of-type(8) {
  animation-delay: 1.4s;
}
.logo-animation.logo-animation--started span:nth-of-type(9) {
  animation-delay: 1.6s;
}
.logo-animation.logo-animation--started span:nth-of-type(10) {
  animation-delay: 1.8s;
}
.logo-animation.logo-animation--started span:nth-of-type(11) {
  animation-delay: 2s;
}
.logo-animation.logo-animation--started span:nth-of-type(12) {
  animation-delay: 2.2s;
}
.logo-animation.logo-animation--started span:nth-of-type(13) {
  animation-delay: 2.4s;
}
.logo-animation.logo-animation--started span:nth-of-type(14) {
  animation-delay: 2.6s;
}
@keyframes revolveScale {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.5;
    transform: translate(0, 0) rotate(0) scale(0.3);
  }
  100% {
    transform: translate(0) rotate(0) scale(1);
    opacity: 1;
  }
}

/* ================================================================================
細分化された汎用パーツ
================================================================================ */
/* ================================================================================
タイトル
================================================================================ */
.block-titile {
  background-image: url(../images/bg_title.svg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 108px 37px;
  margin-bottom: 40px;
  padding-top: 40px;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
  color: #1D8A48;
}
@media screen and (max-width : 767.99px) {
  .block-titile {
    background-size: 68px 23px;
    margin-bottom: 30px;
    padding-top: 30px;
    font-size: 2.6rem;
  }
}
.block-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 384px;
  width: 100%;
  height: 60px;
  margin: 0 auto 40px;
  background-color: #0B72A7;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
}
@media screen and (max-width : 767.99px) {
  .block-subtitle {
    height: 50px;
    margin: 0 auto 30px;
    font-size: 2.2rem;
  }
}

/* ================================================================================
ビデオ
================================================================================ */
iframe.youtube-16-9 {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

/* ================================================================================
スライド
================================================================================ */
.swiper-wrapper {
  transition-timing-function: linear !important;
}
.swiper-slide {
  height: 168px !important;
  width: auto !important;
  padding-right: 1px;
}
@media screen and (max-width : 767.99px) {
  .swiper-slide {
    height: 129px !important;
  }
}
.swiper-slide img {
  width: auto;
  height: 100%;
}

/* ================================================================================
レイアウト用パーツ
================================================================================ */
/* ================================================================================
基本設定
================================================================================ */
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #1A0E05;
}

a {
  color: #1A0E05;
}

/* =====================================
ヘッダー
===================================== */
.js-header {
  z-index: 99999;
}

.l-header {
  height: 70px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 99997;
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(26, 14, 5, 0.16);
  border-radius: 5px;
  transform: translate(-50%, -50px);
  transition: 0.5s all;
  opacity: 0;
}
@media screen and (max-width : 767.99px) {
  .l-header {
    height: 60px;
    margin-top: 0;
    background: none;
    box-shadow: none;
  }
}
.l-header--show {
  opacity: 1;
  transform: translate(-50%, 0);
}
.l-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 17px 40px;
  margin: 0 auto;
}
@media screen and (max-width : 767.99px) {
  .l-header-inner {
    padding: 0 15px;
  }
}
@media screen and (min-width : 768px) {
  .l-header__application {
    display: none;
  }
}
.l-header__application a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 214px;
  height: 44px;
  background-color: #DF6F36;
  background-image: url(../images/ico_contact.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center right 24px;
  border-radius: 22px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  font-size: 1.4rem;
  transition: 0.3s transform;
}
.l-header-hamburger-wrap {
  width: 80px;
  text-align: right;
}
@media screen and (min-width : 768px) {
  .l-header-hamburger-wrap {
    display: none;
  }
}
.l-header-nav {
  display: flex;
  align-items: center;
  height: 100%;
}
.l-header-nav-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width : 767.99px) {
  .l-header-nav-wrap {
    display: none;
  }
}
.l-header-nav__item {
  padding: 0 15px;
}
.l-header-nav__item a {
  text-decoration: none;
}
.l-header-nav__item a:hover {
  color: #1D8A48;
}
.l-header-nav__item img {
  max-height: 34px;
}

.hamburger {
  position: relative;
  height: 40px;
  width: 40px;
  overflow: hidden;
  transition: all 0.3s;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;
}
.hamburger:before, .hamburger:after {
  backface-visibility: hidden;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition-property: transform;
  width: 100%;
}
.hamburger:before {
  transform: scale(1);
  transition-duration: 0.3s;
}
.hamburger:after {
  transform: scale(0);
  transition-duration: 0s;
}
.hamburger span {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: inline-block;
  height: 2px;
  width: 36px;
  background-color: #fff;
  transform: translateX(-50%) translateY(-50%);
}
.hamburger span:before, .hamburger span:after {
  background-color: #fff;
  backface-visibility: hidden;
  content: "";
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  transition: transform 0.3s;
  width: 100%;
}
.hamburger span:before {
  transform: translateY(-12px) rotate(0deg);
}
.hamburger span:after {
  transform: translateY(12px) rotate(0deg);
}
.hamburger.close-nav:before {
  transform: scale(0);
}
.hamburger.close-nav:after {
  transform: scale(1);
}
.hamburger.close-nav span {
  background-color: #1D8A48;
}
.hamburger.close-nav span:before {
  transform: translateY(0) rotate(40deg);
}
.hamburger.close-nav span:after {
  transform: translateY(0) rotate(-40deg);
}

.l-flex-nav {
  position: fixed;
  z-index: 99998;
  background-color: #1D8A48;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;
}
@media screen and (min-width : 768px) {
  .l-flex-nav {
    display: none;
  }
}
.l-flex-nav.open-nav {
  opacity: 1;
  transition: opacity 0.5s;
  visibility: visible;
  overflow: hidden;
}
.l-flex-nav-list {
  height: calc(100vh - 15px - 60px);
  margin: 60px 15px 15px;
  padding: 40px 0;
  background-color: #fff;
  border-radius: 5px;
}
.l-flex-nav-list__item {
  text-align: center;
  margin-bottom: 40px;
}
.l-flex-nav-list__item img {
  width: 34px;
  margin: 0 20px;
}

/* ================================================================================
フッター
================================================================================ */
/* -------------------------------------------------
.l-footer
---------------------------------------------------- */
.l-footer {
  position: relative;
  padding: 95px 0 20px;
  background: #1D8A48;
  color: #fff;
  text-align: center;
  /*
  コピーライト
  ---------------------------------------------------- */
}
@media screen and (max-width : 767.99px) {
  .l-footer {
    padding-bottom: 70px;
  }
}
.l-footer__application {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width : 767.99px) {
  .l-footer__application {
    display: none;
  }
}
.l-footer__application a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 70px;
  background-color: #DF6F36;
  background-image: url(../images/ico_contact.svg);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center right 40px;
  border-radius: 35px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  transition: 0.3s transform;
}
.l-footer__application a:hover {
  transform: translateY(-10px);
}
.l-footer__logo {
  width: 226px;
  margin: 0 auto 40px;
}
.l-footer__contact {
  margin: 0 auto 35px;
}
.l-footer__contact a {
  color: #fff;
  font-size: 1.4rem;
}
.l-footer__contact a:hover {
  text-decoration: underline;
}
.l-footer__copy {
  font-size: 1.4rem;
  font-weight: 700;
}

/* ================================================================================
追従
================================================================================ */
.l-application--pc, .l-application--sp {
  position: fixed;
  z-index: 9999;
  background-color: #DF6F36;
  text-align: center;
  opacity: 0;
  transition: 0.5s all;
}
.l-application--pc a, .l-application--sp a {
  color: #fff;
  font-weight: bold;
}
.l-application--pc {
  top: 100px;
  right: 0;
  border-radius: 5px 0 0 5px;
  transform: translateX(50px);
  text-align: center;
}
@media screen and (max-width : 767.99px) {
  .l-application--pc {
    display: none;
  }
}
.l-application--pc a {
  display: inline-block;
  padding: 20px 14px;
  transition: 0.5s all cubic-bezier(0.19, 1, 0.22, 1);
}
.l-application--pc a:hover {
  padding-right: 24px;
}
.l-application--pc--show {
  opacity: 1;
  transform: translateX(0);
}
.l-application--sp {
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0;
  background-image: url(../images/ico_contact.svg);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center right 40px;
  border: 1px solid #fff;
  transform: translateY(50px);
}
@media screen and (min-width : 768px) {
  .l-application--sp {
    display: none;
  }
}
.l-application--sp a {
  display: inline-block;
  padding: 18px 0;
}
.l-application--sp--show {
  opacity: 1;
  transform: translateX(0);
}

.l-to-top {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  transform: translateY(50px);
  transition: 0.5s all;
  opacity: 0;
}
@media screen and (max-width : 767.99px) {
  .l-to-top {
    bottom: 60px;
    right: 10px;
  }
}
.l-to-top--show {
  opacity: 1;
  transform: translateY(0);
}
.l-to-top a {
  display: inline-block;
  transition: 0.5s all cubic-bezier(0.19, 1, 0.22, 1);
}
.l-to-top a:hover {
  opacity: 0.8;
}

/* ================================================================================
各ページごとのスタイル
================================================================================ */
/* ================================================================================
トップページ
================================================================================ */
.shugen {
  padding: 60px 0 80px;
}
.shugen p {
  line-height: 1.8;
  margin-bottom: 1.8em;
}
.shugen p:last-child {
  margin-bottom: 0;
}

.about {
  background-color: #1D8A48;
  padding: 80px 0;
}
@media screen and (max-width: 1040px) {
  .about {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1040px) and (max-width : 767.99px) {
  .about {
    padding: 15px;
  }
}
.about-inner {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 0;
  background-color: #fff;
  border-radius: 5px;
}
.about p {
  line-height: 1.8;
  margin-bottom: 1.8em;
}
.about p:last-child {
  margin-bottom: 0;
}
.about-intro {
  margin-bottom: 60px;
}
.about-mind {
  margin-bottom: 60px;
}
.about-mind-item {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;
}
.about-mind-item .text {
  max-width: 610px;
  flex: 1;
  margin-left: 60px;
}
.about-mind-item .photo {
  width: 490px;
  margin-right: -200px;
}
.about-mind-item--reverse {
  flex-direction: row-reverse;
}
.about-mind-item--reverse .text {
  margin-left: 0;
  margin-right: 60px;
}
.about-mind-item--reverse .photo {
  margin-right: 0;
  margin-left: -200px;
}
@media screen and (max-width: 1400px) {
  .about-mind-item .text {
    margin-left: 40px;
  }
  .about-mind-item .photo {
    margin-right: calc((100vw - 1000px) / 2 * -1);
  }
  .about-mind-item--reverse {
    flex-direction: row-reverse;
  }
  .about-mind-item--reverse .text {
    margin-left: 0;
    margin-right: 40px;
  }
  .about-mind-item--reverse .photo {
    margin-right: 0;
    margin-left: calc((100vw - 1000px) / 2 * -1);
  }
}
@media screen and (max-width : 1024px) {
  .about-mind-item {
    display: block;
    max-width: calc(640px + 20px * 2);
    padding: 30px 15px;
    margin: 0 auto;
    border-bottom: 2px dashed #83AD94;
  }
  .about-mind-item:first-child {
    padding-top: 0;
  }
  .about-mind-item:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
  .about-mind-item .text {
    max-width: none;
    margin: 0 0 30px;
  }
  .about-mind-item .photo {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
}
.about-guide {
  margin: 0 auto;
}
.about-guide-item {
  max-width: 750px;
  margin: 0 auto;
  padding: 20px 20px;
  border-bottom: 2px dashed #83AD94;
}
.about-guide-item:first-child {
  padding-top: 0;
}
.about-guide-item:last-child {
  border: none;
  padding-bottom: 0;
}
@media screen and (max-width : 767.99px) {
  .about-guide-item {
    margin: 0 15px;
    padding: 30px 0;
  }
}
.about-guide-item-top {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width : 767.99px) {
  .about-guide-item-top {
    display: block;
    margin-bottom: 10px;
  }
}
.about-guide-item-top .photo {
  max-width: 264px;
}
@media screen and (max-width : 767.99px) {
  .about-guide-item-top .photo {
    margin: 0 auto 10px;
  }
}
.about-guide-item-top .name {
  margin-bottom: 10px;
  color: #1D8A48;
  font-size: 2.2rem;
  font-weight: 700;
}
@media screen and (max-width : 767.99px) {
  .about-guide-item-top .name {
    font-size: 2rem;
    text-align: center;
  }
}
.about-guide-item-top .profile {
  margin-left: 15px;
  font-size: 1.3rem;
  font-weight: 700;
  color: #8D8D8D;
}
.about-guide-item-top .profile li {
  position: relative;
}
.about-guide-item-top .profile li::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #1D8A48;
}

.movie {
  position: relative;
  background-image: url(http://img.youtube.com/vi/Fl8Fw4Xd1K4/maxresdefault.jpg);
  background-size: cover;
  background-position: center center;
  height: 500px;
}
.movie::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 14, 5, 0.5);
}
.movie-inner {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.movie__text {
  width: 100%;
  text-align: center;
  font-weight: 700;
  color: #fff;
}
.movie__text-top {
  display: inline-block;
  margin-bottom: 10px;
  border-bottom: 2px #fff dashed;
  font-size: 2.8rem;
}
@media screen and (max-width : 767.99px) {
  .movie__text-top {
    font-size: 2.6rem;
  }
}
.movie__text-bottom {
  font-size: 1.8rem;
}
@media screen and (max-width : 767.99px) {
  .movie__text-bottom {
    font-size: 1.6rem;
  }
}
.movie__play {
  text-align: center;
}
.movie__play a {
  display: inline-block;
  margin-top: 30px;
  transition: 0.3s transform;
}
.movie__play a img {
  width: 78px;
}
.movie__play a:hover {
  transform: scale(1.1);
}

.tour {
  padding: 60px 0 80px;
  background-color: #FFFAF0;
}
@media screen and (max-width : 767.99px) {
  .tour {
    padding: 60px 0 60px;
  }
}
.tour p {
  line-height: 1.8;
  margin-bottom: 1.8em;
}
.tour p:last-child {
  margin-bottom: 0;
}
.tour-intro {
  background-image: url(../images/bg_tour_intro_pc.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 60px;
}
@media screen and (max-width: 1000px) {
  .tour-intro {
    position: relative;
    background: none;
    border-top: 2px dashed #83AD94;
    border-bottom: 2px dashed #83AD94;
    background-color: #fff;
    margin: 60px 0 95px;
  }
  .tour-intro::after, .tour-intro::before {
    content: "";
    position: absolute;
    width: 100%;
    background-position: center center;
    background-size: contain;
    left: 50%;
    background-repeat: no-repeat;
  }
  .tour-intro::after {
    top: 0;
    transform: translate(-50%, -50%);
    height: 77px;
    background-image: url(../images/bg_tour_intro_sp01.svg);
  }
  .tour-intro::before {
    bottom: 0;
    transform: translate(-50%, 50%);
    height: 87px;
    background-image: url(../images/bg_tour_intro_sp02.svg);
  }
}
.tour-intro__text {
  max-width: 540px;
  margin: 0 auto;
  padding: 158px 0;
}
@media screen and (max-width: 1000px) {
  .tour-intro__text {
    padding: 70px 30px;
  }
}
@media screen and (max-width : 767.99px) {
  .tour-features .u-container {
    padding: 0;
  }
}
.tour-features-item {
  position: relative;
  height: 550px;
  margin-bottom: 60px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item {
    height: auto;
    margin-bottom: 40px;
  }
}
.tour-features-item:last-child {
  margin-bottom: 0;
}
.tour-features-item .photo {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item .photo {
    position: static;
    text-align: center;
  }
}
.tour-features-item .photo img {
  max-height: 550px;
}
.tour-features-item .text {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 5px;
  margin: 0 45px;
  padding: 40px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item .text {
    position: relative;
    transform: none;
    margin: -30px 15px 0;
    padding: 50px 40px 60px;
  }
}
.tour-features-item .text img {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 90px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item .text img {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
  }
}
.tour-features-item--reverse .photo {
  left: auto;
  right: 0;
}
.tour-features-item--reverse .text {
  right: auto;
  left: 0;
}
.tour-features-item--reverse .text img {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
}
.tour-features-item:nth-child(1) .text {
  max-width: 680px;
  background-image: url(../images/bg_tour_features01.svg);
  background-repeat: no-repeat;
  background-size: 76px 61px;
  background-position: bottom 5px right 5px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item:nth-child(1) .text {
    max-width: none;
    background-position: bottom 10px right 15px;
  }
}
.tour-features-item:nth-child(2) .text {
  max-width: 680px;
  background-image: url(../images/bg_tour_features02.svg);
  background-repeat: no-repeat;
  background-size: 165px 164px;
  background-position: bottom 15px left 28px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item:nth-child(2) .text {
    max-width: none;
    background-position: bottom 14px left 9px;
  }
}
.tour-features-item:nth-child(3) .text {
  max-width: 540px;
  background-image: url(../images/bg_tour_features03.svg);
  background-repeat: no-repeat;
  background-size: 46px 145px;
  background-position: bottom 30px right 18px;
}
@media screen and (max-width : 767.99px) {
  .tour-features-item:nth-child(3) .text {
    max-width: none;
    background-position: bottom 10px right 10px;
  }
}

.gallery {
  margin: 60px 0 115px;
}
@media screen and (max-width : 767.99px) {
  .gallery {
    margin: 60px 0;
  }
}
.gallery-top {
  margin-bottom: 40px;
}
@media screen and (max-width : 767.99px) {
  .gallery-top {
    margin-bottom: 20px;
  }
}

/* ================================================================================
トップページ
================================================================================ */
.mainvisual {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.mainvisual__background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 3s opacity;
  opacity: 0;
}
.mainvisual__background--show {
  opacity: 1;
}
.mainvisual__logo {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translateX(-50%);
  height: 40vh;
  transition: 1s opacity;
  opacity: 0;
}
@media screen and (max-width : 767.99px) {
  .mainvisual__logo {
    height: 25vh;
    top: 28%;
  }
}
.mainvisual__logo--show {
  opacity: 1;
}
.mainvisual__logo img {
  max-width: none;
  height: 100%;
}
.mainvisual__text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-weight: 700;
  color: #fff;
  transition: 1s opacity;
  opacity: 0;
}
@media screen and (max-width : 767.99px) {
  .mainvisual__text {
    top: 55%;
  }
}
.mainvisual__text--show {
  opacity: 1;
}
.mainvisual__text-top {
  display: inline-block;
  margin-bottom: 10px;
  border-bottom: 2px #fff dashed;
  font-size: 3vh;
}
.mainvisual__text-bottom {
  font-size: 2vh;
}
.mainvisual__scroll {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  transition: 1s opacity;
  opacity: 0;
}
.mainvisual__scroll--show {
  opacity: 1;
}
.mainvisual__scroll::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 30px;
  background: #fff;
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}
@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 30px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 50px;
    opacity: 0;
  }
}
.mainvisual__scroll span {
  position: absolute;
  color: #fff;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
}