@use '../../global/' as *;

/* ================================================================================
追従
================================================================================ */
.l-application {
  &--pc, &--sp {
    position: fixed;
    z-index: 9999;
    background-color: #DF6F36;
    text-align: center;
    opacity: 0;
    transition: .5s all;

    a {
      color: $basicWhiteColor;
      font-weight: bold;
    }
  }

  &--pc {
    top: 100px;
    right: 0;
    border-radius: 5px 0 0 5px;
    transform: translateX(50px);
    text-align: center;
    @include mq(md) {
      display: none;
    }

    a {
      display: inline-block;
      padding: 20px 14px;
      transition: .5s all cubic-bezier(0.19, 1, 0.22, 1);

      &:hover {
        padding-right: 24px;
      }
    }

    &--show {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &--sp {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    background-image: url(../images/ico_contact.svg);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center right 40px;
    border: 1px solid $basicWhiteColor;
    transform: translateY(50px);
    @include mq(pc) {
      display: none;
    }

    a {
      display: inline-block;
      padding: 18px 0;
    }

    &--show {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.l-to-top {
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  transform: translateY(50px);
  transition: .5s all;
  opacity: 0;
  @include mq(md) {
    bottom: 60px;
    right: 10px;
  }

  &--show {
    opacity: 1;
    transform: translateY(0);
  }

  a {
    display: inline-block;
    transition: .5s all cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      opacity: .8;
    }
  }
}