@use '../../global/' as *;

/* =====================================
ヘッダー
===================================== */
.js-header {
  z-index: 99999;
}
.l-header {
  height: 70px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 99997;
  width: 100%;
  max-width: $basicWidth;
  margin-top: 20px;
  background: $basicWhiteColor;
  box-shadow: 0 3px 6px rgba($basicColor, 0.16);
  border-radius: 5px;
  transform: translate(-50%, -50px);
  transition: .5s all;
  opacity: 0;
  @include mq(md) {
    height: 60px;
    margin-top: 0;
    background: none;
    box-shadow: none;
  }

  &--show {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 17px 40px;
    margin: 0 auto;
    @include mq(md) {
      padding: 0 15px;
    }
  }

  &__application {
    @include mq(pc) {
      display: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 214px;
      height: 44px;
      background-color: $orangeColor;
      background-image: url(../images/ico_contact.svg);
      background-repeat: no-repeat;
      background-size: 15px 15px;
      background-position: center right 24px;
      border-radius: 22px;
      border: 1px solid $basicWhiteColor;
      color: $basicWhiteColor;
      font-weight: 700;
      font-size: 1.4rem;
      transition: .3s transform;
    }
  }

  &-hamburger {
    &-wrap {
      width: 80px;
      text-align: right;

      @include mq(pc) {
        display: none;
      }
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    height: 100%;

    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @include mq(md) {
        display: none;
      }
    }

    &__item {
      padding: 0 15px;
      a {
        text-decoration: none;

        &:hover {
          color: $greenColor;
        }
      }
      img {
        max-height: 34px;
      }
    }
  }
}

.hamburger {
  position: relative;
  height: 40px;
  width: 40px;
  overflow: hidden;
  transition: all 0.3s;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;

  &:before, &:after {
    backface-visibility: hidden;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition-property: transform;
    width: 100%;
  }
  &:before {
    transform: scale(1);
    transition-duration: 0.3s;
  }
  &:after {
    transform: scale(0);
    transition-duration: 0s;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    display: inline-block;
    height: 2px;
    width: 36px;
    background-color: $basicWhiteColor;
    transform: translateX(-50%) translateY(-50%);
    &:before, &:after {
      background-color: $basicWhiteColor;
      backface-visibility: hidden;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateZ(0);
      transition: transform 0.3s;
      width: 100%;
    }
    &:before {
      transform: translateY(-12px) rotate(0deg);
    }
    &:after {
      transform: translateY(12px) rotate(0deg);
    }
  }

  &.close-nav {
    &:before {
      transform: scale(0);
    }
    &:after {
      transform: scale(1);
    }

    span {
      background-color: $greenColor;
      &:before, &:after {
      }
      &:before {
        transform: translateY(0) rotate(40deg);
      }
      &:after {
        transform: translateY(0) rotate(-40deg);
      }
    }
  }
}

.l-flex-nav {
  position: fixed;
  z-index: 99998;
  background-color: $greenColor;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s 0.5s;
  @include mq(pc) {
    display: none;
  }

  &.open-nav {
    opacity: 1;
    transition: opacity 0.5s;
    visibility: visible;
    overflow: hidden;
  }

  &-list {
    $marginTop: 60px;
    $margin: 15px;
    height: calc(100vh - #{$margin} - #{$marginTop});
    margin: $marginTop $margin $margin;
    padding: 40px 0;
    background-color: $basicWhiteColor;
    border-radius: 5px;

    &__item {
      text-align: center;
      margin-bottom: 40px;

      img {
        width: 34px;
        margin: 0 20px;
      }
    }
  }
}
